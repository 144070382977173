<template>
  <div class="text-center">
    <div class="container-header-matter-program">
      <h4 class="mt-4">
        {{
          $getVisibleNames(
            "manual.matter_program",
            false,
            "Programa de la Asignatura"
          ).toUpperCase()
        }}
        <AlternativeNameButton
          :table_name="'manual.matter_program'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
        <template v-if="matter">
          <template v-if="allows_crud">
            <!-- (allows_crud && matter.use_matter_values) ||
            (allows_crud && !matter.use_matter_values && !matter.is_closed) -->
            <ButtonSmall
              v-can="'teaching.change_matter'"
              :tooltip_text="`Editar ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )}`"
              :click_button="
                () => $bvModal.show(`modal-edit-matter-${matter.id}`)
              "
              :class="``"
              :icon="'square'"
            ></ButtonSmall>
            <!-- <button-edit
              v-can="'teaching.change_matter'"
              @click="$bvModal.show(`modal-edit-matter-${matter.id}`)"
              v-b-tooltip.v-secondary.noninteractive="
                `Editar ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`
              "
            ></button-edit> -->
          </template>
          <b-modal
            :id="`modal-edit-matter-${matter.id}`"
            hide-footer
            :title="`Editar ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )}`"
            size="xl"
            ignore-enforce-focus-selector="#sidebar-right"
          >
            <MatterForm
              :Matter="matter"
              :egress_profile_id="matter.egress_profile"
              :show_delete_button="false"
              @change_bibliographic_resource="
                () => bibliographic_resource_key++
              "
              @change_teaching_profile="() => teaching_profile_key++"
              @change_assistant_profile="() => assistant_profile_key++"
              @change_teaching_support_resources="() => support_resource_key++"
              @update="forceRerenderMatterPresentation"
              @updated="forceRerenderMatterPresentation"
            ></MatterForm>
          </b-modal>
        </template>
        <!-- <template v-if="isClosedOldMatter">
        <b-button
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="
            `Cambiar el estado del ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`
          "
          @click="changeStateOldMatter()"
        >
          <template v-if="oldMatter && oldMatter.is_closed">
            <b-icon icon="lock-fill" scale="1"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="unlock-fill" scale="1"></b-icon>
          </template>
        </b-button>
      </template> -->

        <!-- <template v-if="isClosedMatter">
        <b-button
          class="noprint"
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="
            `Cambiar el estado de la ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )}`
          "
          @click="changeStateMatter()"
        >
          <template v-if="matter && matter.is_closed">
            <b-icon class="btn-actions" icon="lock" scale="1"></b-icon>
          </template>
          <template v-else>
            <b-icon class="btn-actions" icon="unlock" scale="1"></b-icon>
          </template>
        </b-button>
      </template> -->
      </h4>
      <!-- Contenido del toast -->
      <GenericAlertBox :alerts="filteredAlerts"></GenericAlertBox>
      <!-- Candado para bloquear asignatura -->

      <template
        v-if="
          isClosedOldMatter &&
          oldMatter &&
          !isNaN(oldMatter.id) &&
          allows_crud &&
          ((institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc)
        "
      >
        <div>
          <b-button
            size="sm"
            class="btn-close-open-matter"
            variant="none"
            v-b-tooltip.v-secondary.noninteractive="
              !oldMatter.isclosed && !matter.is_closed
                ? `Cerrar ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`
                : `Abrir ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`
            "
            @click="changeStateOldMatter()"
          >
            <template
              v-if="
                oldMatter && oldMatter.is_closed && matter && matter.is_closed
              "
            >
              <b-icon icon="lock-fill" scale="1"></b-icon>
            </template>
            <template v-else>
              <b-icon icon="unlock-fill" scale="1"></b-icon>
            </template>
          </b-button>
        </div>
      </template>
    </div>
    <NewMatterPresentation
      v-if="oldMatter && matter"
      :key="`${oldMatter.id}-${matter.id}-${oldMatter.is_closed}-${matter.is_closed}`"
      :Matter="matter"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud"
    ></NewMatterPresentation>
    <template
      v-if="
        (institution && institution.internal_use_id == 'duoc_uc') ||
        $debug_change_duoc
      "
    >
      <div class="learning-suitcase-buttons noprint">
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningMatterDescription(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> DESCRIPCIÓN
          ASIGNATURA</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningEvaluation(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> EVALUACIÓN</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningExperience(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> EXPERIENCIAS DE
          APRENDIZAJE</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningSuitcase(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> PIA SEDE</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningSuitcaseStudent(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> PA ESTUDIANTE</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningSuitcaseTeacher(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> PDA DOCENTE
        </b-button>
        <span
          v-if="
            oldMatter &&
            user &&
            user.is_superuser &&
            show_bibliographic_resources
          "
          v-b-tooltip.v-danger.top.noninteractive="
            `${
              show_bibliographic_resources && bibliographic_resources_not_found
                ? 'No se encontraron Recursos Bibliográficos con el Código de la ' +
                  $getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )
                : ''
            }`
          "
        >
          <b-button
            v-if="
              oldMatter &&
              user &&
              user.is_superuser &&
              show_bibliographic_resources
            "
            class="learning-suitcase-btn ml-1"
            :disabled="bibliographic_resources_not_found"
            @click="
              () => {
                if (
                  show_bibliographic_resources &&
                  !bibliographic_resources_not_found
                )
                  $bvModal.show(`modal-matter-bibliographic-${matter.id}`);
              }
            "
            size="sm"
          >
            <!-- @click="redirectToBibliophicResource()" -->
            <!-- <b-icon icon="box-arrow-up-right"></b-icon> -->
            RECURSOS BIBLIOGRÁFICOS
          </b-button>
        </span>
        <b-modal
          v-if="oldMatter"
          :id="`modal-matter-bibliographic-${matter.id}`"
          size="xl"
          hide-footer
        >
          <template slot="modal-title">
            <b-button
              variant="primary"
              :href="iframeUrl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon icon="box-arrow-up-right"></b-icon>
              Abrir en otra pestaña
            </b-button>
          </template>
          <div style="min-height: 600px">
            <iframe
              :src="iframeUrl"
              frameborder="0"
              width="100%"
              height="600px"
            ></iframe>
          </div>
        </b-modal>
      </div>
    </template>
    <template v-else>
      <div class="text-left mb-2">
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningEvaluation(egress_profile_matter_id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> EVALUACIÓN</b-button
        >
      </div>
    </template>
    <template v-if="matter && !matter.use_matter_values">
      <DescriptorsContainer
        :ref_component="'EPMatter'"
        :Title_text="'Programas'"
        :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
        :Title="egressProfileMatterDescriptor"
        :Descriptors="epmMiddleDescriptors"
        :allows_crud="allows_crud && matter && !matter.is_closed"
        :Order="1"
        :VisibleOrder="false"
        :ProgramView="true"
        :matter="matter"
        @updateOrder="updateOrderEpmMiddleDescriptors"
        @updateState="updateVisibleEpmMiddleDescriptors"
        @createDescription="createEpmMiddleDescriptors"
        @updateDescription="updateEpmMiddleDescriptors"
        @createTitle="createProfileMatterDescriptors"
        @updateTitle="updateProfileMatterDescriptors"
        @deleteDescriptors="deleteProfileMatterDescriptors"
        @deleteTitle="deleteTitle"
      >
      </DescriptorsContainer>
    </template>
    <template v-else>
      <DescriptorsContainer
        :ref_component="'Matter'"
        :Title_text="'Programas'"
        :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
        :Title="egressProfileMatterDescriptor"
        :Descriptors="matterMiddleDescriptors"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        :Order="1"
        :VisibleOrder="false"
        :ProgramView="true"
        :matter="matter"
        :oldMatter="oldMatter"
        @updateOrder="updateOrderMatterMiddleDescriptors"
        @updateState="updateVisibleMatterMiddleDescriptors"
        @createDescription="createMatterMiddleDescriptors"
        @updateDescription="updateMatterMiddleDescriptors"
        @createTitle="createProfileMatterDescriptors"
        @updateTitle="updateProfileMatterDescriptors"
        @deleteDescriptors="deleteMatterMiddleDescriptors"
        @deleteTitle="deleteTitle"
      >
      </DescriptorsContainer>
    </template>
    <table>
      <tbody>
        <tr class="tr-competences">
          <th colspan="10" rowspan="1">
            <div class="d-flex">
              <div
                class="mx-auto align-self-center"
                :class="{
                  'competences-header':
                    allows_crud && matter && !matter.is_closed,
                }"
              >
                <span class="title-competences">
                  {{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}
                  A LAS QUE TRIBUTA LA
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    ).toUpperCase()
                  }}
                </span>
              </div>
              <ButtonSmall
                v-if="allows_crud && matter && !matter.is_closed"
                :tooltip_text="`Editar ${$getVisibleNames(
                  'mesh.competence',
                  true,
                  'Competencias'
                )} a las que tributa la ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`"
                :click_button="
                  () => $bvModal.show(`modal-select-competence-${matter.id}`)
                "
                :class="`btn-edit-competences`"
                :icon="'square'"
              ></ButtonSmall>
              <!-- <button-edit
                v-if="allows_crud && matter && !matter.is_closed"
                class="btn-edit-competences"
                v-b-tooltip.v-secondary.left.noninteractive="
                  `Editar ${$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencias'
                  )} a las que tributa la ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`
                "
                @click="$bvModal.show(`modal-select-competence-${matter.id}`)"
                >Editar
                {{
                  $getVisibleNames("mesh.competence", true, "Competencias")
                }}</button-edit
              > -->
            </div>
          </th>
        </tr>
        <tr
          v-for="competence_type in competenceTypes"
          :key="competence_type.id"
        >
          <td
            colspan="10"
            rowspan="1"
            v-if="
              matterCompetences.length > 0 &&
              competenceList.filter(
                (x) =>
                  x.type == competence_type.id &&
                  matterCompetences.includes(x.id)
              ).length > 0
            "
          >
            <div class="wrapper">
              <div>
                <strong :title="competence_type.description">{{
                  competence_type.name.toUpperCase()
                }}</strong>
              </div>
              <div
                v-for="competence in competenceList.filter(
                  (x) =>
                    x.type == competence_type.id &&
                    matterCompetences.includes(x.id)
                )"
                :key="competence.id"
              >
                <div class="d-flex" style="text-align: justify">
                  <!-- <span style="position: absolute">
                    {{
                      cycleOrder(competence.cycle)
                        ? cycleOrder(competence.cycle) + "." + competence.order
                        : competence.order
                    }}
                  </span> -->
                  <!-- {{ cycleOrder(competence.cycle) }}.{{ competence.order }} -->
                  <SentenceContainer class="w-100 mb-1" :Sentence="competence">
                    <template #order>
                      <span class="mr-1">
                        {{
                          cycleOrder(competence.cycle)
                            ? cycleOrder(competence.cycle) +
                              "." +
                              competence.order
                            : competence.order
                        }}<span
                          v-if="
                            competence.sub_order > 0 &&
                            institution &&
                            institution.show_competence_sub_order
                          "
                          >.{{ competence.sub_order }}</span
                        >
                      </span>
                    </template>
                  </SentenceContainer>
                  <div
                    style="width: 10%"
                    v-if="
                      profileMentions(competence.profile_competence) != null &&
                      profileMentions(competence.profile_competence).length > 0
                    "
                  >
                    <strong
                      >{{
                        $getVisibleNames(
                          "mesh.egressprofilecompetencemention",
                          profileMentions(competence.profile_competence)
                            .length > 1,
                          "Mención"
                        )
                      }}: </strong
                    >{{
                      profileMentions(competence.profile_competence).length > 1
                        ? "(" +
                          profileMentions(competence.profile_competence).join(
                            ", "
                          ) +
                          ")"
                        : profileMentions(competence.profile_competence)[0]
                    }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colspan="10"
            rowspan="1"
            v-if="
              matterCompetences.length > 0 &&
              competenceList.filter(
                (x) => x.type == null && matterCompetences.includes(x.id)
              ).length > 0
            "
          >
            <div class="wrapper">
              <div>
                <strong>
                  {{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}
                </strong>
              </div>
              <div
                v-for="competence in competenceList.filter(
                  (x) => x.type == null && matterCompetences.includes(x.id)
                )"
                :key="competence.id"
              >
                <p style="text-align: justify">
                  <span style="position: absolute"
                    >{{ competence.order }}.{{
                      cycleOrder(competence.cycle)
                    }}</span
                  >
                  <SentenceContainer
                    style="padding-left: 12px"
                    :Sentence="competence"
                  >
                  </SentenceContainer>
                </p>
              </div>
            </div>
          </td>
        </tr>
        <template v-if="allows_crud">
          <CompetenceModal
            @ok="updateCompetences"
            :selected_ids="matterCompetences"
            :modal_id="`modal-select-competence-${matter.id}`"
            :matrix2_competences="competenceList"
            :matrix2="true"
            :egress_profile_id="egressProfile ? egressProfile.id : null"
            :matter_id="matter.id"
          ></CompetenceModal>
        </template>
        <tr>
          <td v-if="matterCompetences.length == 0">
            <section class="py-1">
              Esta
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                )
              }}
              no tributa a ninguna
              {{ $getVisibleNames("mesh.competence", false, "Competencia") }}.
            </section>
          </td>
        </tr>
        <EvaluationCriteriaMacroComponent
          v-if="oldMatter && matter"
          :oldMatter="oldMatter"
          :allows_crud="allows_crud"
          :profileMatter="matter"
        ></EvaluationCriteriaMacroComponent>
      </tbody>
    </table>
    <!-- // TODO: OCULTADO TABLA CAPACIDADES -->
    <MatterCapacities
      v-if="
        (institution && institution.internal_use_id == 'duoc_uc') ||
        $debug_change_duoc
      "
      :matrix2_competences="competenceList"
      :egress_profile_matter_id="egress_profile_matter_id"
      :allows_crud="allows_crud && matter && !matter.is_closed"
    ></MatterCapacities>
    <template
      v-if="
        institution &&
        (institution.internal_use_id == 'ciisa_uss' ||
          institution.show_competence_unit)
      "
    >
      <ButtonMedium
        v-if="allows_crud && oldMatter && !oldMatter.is_closed"
        :tooltip_text="``"
        :click_button="openModalCompetenceUnit"
        :custom_class="`my-2`"
        :text_button="`Agregar ${$getVisibleNames(
          'teaching.tempcompetenceunit',
          false,
          'Unidad de competencia'
        )}`"
        :icon="'plus'"
        :variant="'primary'"
      >
      </ButtonMedium>
      <!-- <b-button
        v-if="allows_crud && oldMatter && !oldMatter.is_closed"
        size="sm"
        class="secondary-button my-2"
        @click="openModalCompetenceUnit()"
        ><b-icon-plus></b-icon-plus>Agregar
        {{
          $getVisibleNames(
            "teaching.tempcompetenceunit",
            false,
            "Unidad de competencia"
          )
        }}</b-button
      > -->
      <b-modal
        :id="`add-unit-competence-modal`"
        hide-footer
        :title="`Agregar ${$getVisibleNames(
          'teaching.tempcompetenceunit',
          false,
          'Unidad de competencia'
        )}`"
        size="lg"
        ignore-enforce-focus-selector="#sidebar-right"
        @hide="hideModalCompetenceUnit()"
      >
        <CompetenceUnitForm
          v-if="oldMatter"
          :matter_id="oldMatter.id"
          @created="$bvModal.hide(`add-unit-competence-modal`)"
          @close_modal="$bvModal.hide(`add-unit-competence-modal`)"
        ></CompetenceUnitForm>
      </b-modal>
      <template v-if="oldMatter && temp_competence_units.length > 0">
        <CompetenceUnits
          v-for="temp_competence_unit in temp_competence_units"
          :key="`unit-competence-${temp_competence_unit.id}`"
          :oldMatter="oldMatter"
          :egress_profile_matter_id="egress_profile_matter_id"
          :tempCompetenceUnit="temp_competence_unit"
          :allows_crud="allows_crud"
        ></CompetenceUnits>
      </template>
    </template>
    <CompetenceUnits
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :egress_profile_matter_id="egress_profile_matter_id"
      :tempCompetenceUnit="null"
      :allows_crud="allows_crud"
    ></CompetenceUnits>
    <!-- <div v-if="studyUnitsList.length > 0 && oldMatter">
      <draggable
        v-model="studyUnitsList"
        :disabled="!allows_crud"
        v-bind="dragOptions"
        class="list-group"
        group="study_unit"
        handle=".handle"
      >
        <transition-group type="transition" name="flip-list">
          <template>
            <UnitSection
              v-for="study_unit in studyUnitsList"
              :study_unit_id="study_unit.id"
              :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
              :egress_profile_matter_id="egress_profile_matter_id"
              :matter_id="oldMatter.id"
              :key="`unit-section-${study_unit.id}`"
              class="my-2"
            ></UnitSection>
          </template>
        </transition-group>
      </draggable>
    </div> -->
    <!-- <b-button
      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
      size="sm"
      class="secondary-button my-2"
      @click="$bvModal.show(`add-study-unit-modal`)"
      ><b-icon-plus></b-icon-plus>Agregar
      {{ $getVisibleNames("teaching.ramicro", true, "RA Micro") }}</b-button
    > -->
    <div
      v-if="
        (institution &&
          (institution.internal_use_id == 'duoc_uc' ||
            institution.internal_use_id == 'ciisa_uss')) ||
        $debug_change_duoc
      "
    >
      <!-- :key="'key_learning_experience-' + key_learning_experience" -->
      <LearningExperience
        v-if="oldMatter"
        :matter_id="oldMatter.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        :unique_ra="institution.internal_use_id == 'ciisa_uss' ? true : false"
        :show_competence_unit="
          institution.internal_use_id == 'ciisa_uss' ? true : false
        "
      >
        <!-- @change_matter_evaluations="() => key_matter_program_evaluation++" -->
      </LearningExperience>
    </div>
    <template v-if="matter && !matter.use_matter_values">
      <EPMatterProgramEvaluation
        :egress_profile_matter_id="egress_profile_matter_id"
        :allows_crud="allows_crud && matter && !matter.is_closed"
        @created_support_resource="createdSupportResource"
      ></EPMatterProgramEvaluation>
    </template>
    <template v-else-if="oldMatter">
      <!-- :key="'key_matter_program_evaluation-' + key_matter_program_evaluation" -->
      <MatterProgramEvaluation
        :matter_id="oldMatter.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        @created_support_resource="createdSupportResource"
      ></MatterProgramEvaluation>
      <!-- @update_matter_evaluations="() => key_learning_experience++" -->
    </template>
    <div class="mx-auto">
      <TeachingSupportResourcesComponent
        v-if="oldMatter"
        :key="'support_resource_key-' + support_resource_key"
        :oldMatter="oldMatter"
        :allows_crud="allows_crud"
        :supportResources="[]"
        :matter_form_view="false"
      ></TeachingSupportResourcesComponent>
      <!-- :supportResources="support_resources" -->
      <BibliographicResourcesComponent
        v-if="oldMatter"
        :key="'bibliographic_resource_key-' + bibliographic_resource_key"
        :oldMatter="oldMatter"
        :matter="matter"
        :allows_crud="allows_crud"
      ></BibliographicResourcesComponent>
      <ResourcesLearningEnvComponent
        v-if="oldMatter"
        :oldMatter="oldMatter"
        :allows_crud="allows_crud"
      ></ResourcesLearningEnvComponent>
      <TeachingProfileComponent
        v-if="oldMatter && matter"
        :key="'teaching_profile_key-' + teaching_profile_key"
        :oldMatter="oldMatter"
        :allows_crud="allows_crud && !oldMatter.teacher_profile_locked_view"
        :matter="matter"
      ></TeachingProfileComponent>
      <AssistantProfileComponent
        v-if="oldMatter"
        :key="'assistant_profile_key-' + assistant_profile_key"
        :oldMatter="oldMatter"
        :allows_crud="allows_crud && !oldMatter.assistant_profile_locked_view"
      ></AssistantProfileComponent>
    </div>
    <!-- modal -->
    <!-- <b-modal
      :id="`add-study-unit-modal`"
      hide-footer
      :title="`Agregar ${$getVisibleNames(
        'teaching.ramicro',
        true,
        'RA Micro'
      )}`"
      size="lg"
    >
      <StudyUnitForm
        v-if="oldMatter"
        :matter_id="oldMatter.id"
        :egress_profile_matter_id="egress_profile_matter_id"
        @created="$bvModal.hide('add-study-unit-modal')"
        @close_modal="$bvModal.hide('add-study-unit-modal')"
      ></StudyUnitForm>
    </b-modal> -->
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="false"
    ></EvaluationCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
// import draggable from "vuedraggable";
import { URL } from "@/utils/globals";

export default {
  name: "MatterProgram",
  components: {
    // ResizeAuto: () => import("@/components/reusable/ResizeAuto"),
    // TextArea: () => import("@/components/reusable/TextArea"),
    // draggable,
    NewMatterPresentation: () =>
      import("./Components/NewMatterPresentation.vue"),
    EvaluationCriteriaMacroComponent: () =>
      import("./Components/EvaluationCriteriaMacroComponent"),
    TeachingSupportResourcesComponent: () =>
      import("./Components/TeachingSupportResourcesComponent"),
    ResourcesLearningEnvComponent: () =>
      import("./Components/ResourcesLearningEnvComponent.vue"),
    BibliographicResourcesComponent: () =>
      import("./Components/BibliographicResourcesComponent.vue"),
    TeachingProfileComponent: () =>
      import("./Components/TeachingProfileComponent.vue"),
    AssistantProfileComponent: () =>
      import("./Components/AssistantProfileComponent.vue"),
    LearningExperience: () => import("./Components/LearningExperience.vue"),
    EPMatterProgramEvaluation: () =>
      import("./EPMatterProgramEvaluation/EPMatterProgramEvaluation.vue"),
    MatterProgramEvaluation: () =>
      import("./MatterProgramEvaluation/MatterProgramEvaluation.vue"),
    MatterForm: () => import("@/components/mesh/Matrix2/MatterForm"),
    // TODO: OCULTADO TABLA CAPACIDADES
    MatterCapacities: () => import("./Components/MatterCapacities"),
    DescriptorsContainer: () =>
      import("@/components/reusable/DescriptorsContainer"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    GenericAlertBox: () => import("@/components/reusable/GenericAlertBox"),
    // NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    // StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    // UnitSection: () => import("./Components/UnitSection"),
    CompetenceUnits: () => import("./Components/CompetenceUnits"),
    CompetenceUnitForm: () => import("./Components/CompetenceUnitForm"),
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    // RAMacroForm: () => import("@/components/mesh/Matrix2/RAMacroForm"),
    CompetenceModal: () =>
      import(
        "@/components/mesh/CurricularResources/Competence/CompetenceModal"
      ),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    // matter_id: {
    //   type: Number,
    //   required: true,
    // },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // support_resources: [],
      componentKey: 0,
      support_resource_key: 0,
      bibliographic_resource_key: 0,
      teaching_profile_key: 0,
      assistant_profile_key: 0,
      // key_matter_program_evaluation: 0,
      show_bibliographic_resources: false,
      bibliographic_resources_not_found: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_access: names.USER_POSITION,
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      methodologicalStretegies: names.METHODOLOGICAL_STRATEGIES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      raMacros: names.RA_MACROS,
      profile_competences: names.PROFILE_COMPETENCES,
      competenceTypes: names.COMPETENCE_TYPES,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      competences: names.COMPETENCES,
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      profile_cycles: names.PROFILE_CYCLES,
      egress_profile_matter_descriptors:
        names.EGRESS_PROFILE_MATTER_DESCRIPTORS,
      epm_middle_descriptors: names.EPM_MIDDLE_DESCRIPTORS,
      m_middle_descriptors: names.M_MIDDLE_DESCRIPTORS,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
      competence_units: names.COMPETENCE_UNITS,
    }),
    isClosedMatter() {
      if (
        (this.user && this.user.is_superuser) ||
        (this.oldMatter &&
          this.$hasObjectPermission(
            "special.close_base_matter",
            "special",
            this.oldMatter.id
          ))
        // ||(this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    isClosedOldMatter() {
      if (
        (this.user && this.user.is_superuser) ||
        (this.oldMatter &&
          this.$hasObjectPermission(
            "special.close_base_matter",
            "special",
            this.oldMatter.id
          ) &&
          !this.oldMatter.is_closed) ||
        (this.oldMatter &&
          this.$hasObjectPermission(
            "special.open_base_matter",
            "special",
            this.oldMatter.id
          ) &&
          this.oldMatter.is_closed)
      ) {
        return true;
      } else return false;
    },
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    filteredAlerts() {
      return [
        {
          tooltip_text: `Asigne una ${this.$getVisibleNames(
            "mesh.competence",
            false,
            "Competencia"
          )} a la ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )} en el apartado ${this.$getVisibleNames(
            "mesh.competence",
            false,
            "Competencia"
          )} a las que tributa la ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
          title_alert: `${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )} sin ${this.$getVisibleNames(
            "mesh.competence",
            false,
            "Competencia"
          )}`,
          dismiss_btn: true,
          variant: "danger",
          show_alert: this.matter && this.matter.competences.length == 0,
        },
      ];
    },
    iframeUrl() {
      return `${URL}/integrations/lti_launcher/?matter=${this.oldMatter.id}`;
    },
    egressProfile() {
      return this.egressProfiles.find(
        (x) => x.id == this.matter.egress_profile
      );
    },
    epmMiddleDescriptors() {
      return this.epm_middle_descriptors.filter(
        (x) => x.egress_profile_matter == this.egress_profile_matter_id
      );
    },
    matterMiddleDescriptors() {
      if (!this.matter) return [];
      return this.m_middle_descriptors.filter(
        (x) => x.matter == this.matter.matter
      );
    },
    egressProfileMatterDescriptor() {
      if (this.matter && !this.matter.use_matter_values) {
        const epm_middle = this.epmMiddleDescriptors.map((x) => x.descriptor);
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
          return this.egress_profile_matter_descriptors.filter(
            (x) =>
              (x.is_default || epm_middle.includes(x.id)) &&
              !this.$equals(
                x.internal_use_id,
                "duoc_pmd_te_evaluation_situation"
              ) &&
              !this.$equals(x.internal_use_id, "duoc_pmd_te_orientations") &&
              !this.$equals(
                x.internal_use_id,
                "duoc_pmd_matter_description_superior"
              ) &&
              !this.$equals(
                x.internal_use_id,
                "duoc_key_suggestions_and_warnings"
              )
          );
        else
          return this.egress_profile_matter_descriptors.filter(
            (x) => x.is_default || epm_middle.includes(x.id)
          );
      } else {
        const matter_middle = this.matterMiddleDescriptors.map(
          (x) => x.descriptor
        );
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
          return this.egress_profile_matter_descriptors.filter(
            (x) =>
              (x.is_default || matter_middle.includes(x.id)) &&
              !this.$equals(
                x.internal_use_id,
                "duoc_pmd_te_evaluation_situation"
              ) &&
              !this.$equals(x.internal_use_id, "duoc_pmd_te_orientations") &&
              !this.$equals(
                x.internal_use_id,
                "duoc_pmd_matter_description_superior"
              ) &&
              !this.$equals(
                x.internal_use_id,
                "duoc_key_suggestions_and_warnings"
              )
          );
        else
          return this.egress_profile_matter_descriptors.filter(
            (x) => x.is_default || matter_middle.includes(x.id)
          );
      }
    },
    egressProfileCycleId() {
      if (!this.egressProfile) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egressProfile.id) list.push(x.cycle);
      });
      return list;
    },
    matterCompetences() {
      if (!this.matter) return [];
      return this.matter.competences;
    },
    competenceList() {
      if (!this.egressProfile) return [];
      if (!this.matter) return [];
      let list = [];
      let competences = [];
      let cycles = [];
      this.profile_cycles.forEach((x) => {
        if (
          this.matter.cycles.includes(x.id) ||
          (this.institution &&
            this.institution.filter_competence_in_matrix_2 == false)
        ) {
          cycles.push(x.cycle);
        }
      });
      if (this.egressProfileCycleId.length > 0 && cycles.length > 0) {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egressProfile.id &&
              (cycles.includes(x.cycle) || x.cycle == null)) ||
            (x.egress_profile == this.egressProfile.id &&
              this.matter.competences.includes(x.competence))
        );
      } else {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egressProfile.id && x.cycle == null) ||
            (x.egress_profile == this.egressProfile.id &&
              this.matter.competences.includes(x.competence))
        );
      }
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          let profile_cycle_order = this.profile_cycles.find(
            (x) =>
              x.cycle == competence.cycle &&
              x.egress_profile == competence.egress_profile
          );
          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              sub_order: competence.sub_order,
              egress_profile: competence.egress_profile,
              capacities: competence.capacities,
              profile_competence: competence.id,
              cycle: competence.cycle,
              profile_cycle_order: profile_cycle_order
                ? profile_cycle_order.order
                : 9999,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
              selected: get_competence.selected,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.sub_order > b.sub_order) return 1;
        if (a.sub_order < b.sub_order) return -1;
        return 0;
      });
    },
    temp_competence_units() {
      if (!this.oldMatter) return [];
      return this.competence_units.filter((x) => x.matter == this.oldMatter.id);
    },
  },
  methods: {
    // Metodo para  cambiar estado del candado de la asignatura
    changeStateOldMatter() {
      if (this.oldMatter && this.matter) {
        if (
          this.oldMatter.is_closed == false &&
          this.matter.is_closed == false
        ) {
          if (this.matter.competences.length == 0) {
            this.$swal({
              title: `¿Está seguro de que desea cerrar la ${this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              )}?`,
              text: `Teniendo en cuenta que no ha asignado ninguna ${this.$getVisibleNames(
                "mesh.competence",
                false,
                "Competencia"
              )}`,
              type: "warning",
              confirmButtonText: "Aceptar",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.changeStateButtonOldMatter();
                this.changeStateButtonMatter();
              }
            });
          } else {
            this.changeStateButtonOldMatter();
            this.changeStateButtonMatter();
          }
        }
        if (this.oldMatter.is_closed == false && this.matter.is_closed == true)
          this.changeStateButtonOldMatter();

        if (
          this.oldMatter.is_closed == true &&
          this.matter.is_closed == false
        ) {
          if (this.matter.competences.length == 0) {
            this.$swal({
              title: `¿Está seguro de que desea cerrar la ${this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              )}?`,
              text: `Teniendo en cuenta que no ha asignado ninguna ${this.$getVisibleNames(
                "mesh.competence",
                false,
                "Competencia"
              )}?`,
              type: "warning",
              confirmButtonText: "Aceptar",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.changeStateButtonMatter();
              }
            });
          } else {
            this.changeStateButtonMatter();
          }
        }

        if (this.oldMatter.is_closed == true && this.matter.is_closed == true) {
          this.$restful
            .Get(
              `/mesh/reopen_egress_profile_matter/?egress_profile_matter=${this.matter.id}`
            )
            .then(() => {
              this.matter.is_closed = !this.matter.is_closed;
              this.changeStateButtonOldMatter();
            });
        }
      }
    },
    changeStateButtonMatter() {
      const payloadMatter = {
        egress_profile_matter_id: this.matter.id,
        item: { is_closed: !this.matter.is_closed },
      };
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payloadMatter)
        .then((response) => {
          this.matter.is_closed = response.is_closed;
          this.showOrHideAiModule();
        });
    },
    changeStateButtonOldMatter() {
      const payloadOldMatter = {
        matter_id: this.oldMatter.id,
        item: {
          is_closed: !this.oldMatter.is_closed,
        },
      };
      this.$store
        .dispatch(names.PATCH_MATTER, payloadOldMatter)
        .then((response) => {
          this.oldMatter.is_closed = response.is_closed;
          this.showOrHideAiModule();
        });
    },
    showOrHideAiModule() {
      if (
        this.matter &&
        !this.matter.is_closed &&
        this.oldMatter &&
        !this.oldMatter.is_closed
      )
        this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
      else this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    },
    profileMentions(profile_competence) {
      if (
        profile_competence &&
        this.matter &&
        this.profile_competences_mention.filter(
          (x) => x.egress_profile == this.matter.egress_profile
        ).length > 0
      ) {
        const mentions = this.profile_competences_mention
          .filter(
            (x) =>
              x.egress_profile == this.matter.egress_profile &&
              x.egress_profile_competences.includes(profile_competence)
          )
          .map((x) => x.name);
        if (mentions.length > 0) return mentions;
        else return ["Común"];
      } else return null;
    },
    // changeStateOldMatter() {
    //   const payload = {
    //     matter_id: this.oldMatter.id,
    //     item: {
    //       is_closed: !this.oldMatter.is_closed,
    //     },
    //   };
    //   this.$store.dispatch(names.PATCH_MATTER, payload);
    // },
    createdSupportResource() {
      // response
      // this.support_resources.push(response);
      this.support_resource_key += 1;
    },
    changeStateMatter() {
      if (!this.matter.is_closed) {
        const payload = {
          egress_profile_matter_id: this.matter.id,
          item: { is_closed: !this.matter.is_closed },
        };
        this.$store.dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload);
      } else {
        this.$restful
          .Get(
            `/mesh/reopen_egress_profile_matter/?egress_profile_matter=${this.matter.id}`
          )
          .then(() => {
            this.matter.is_closed = !this.matter.is_closed;
          });
      }
    },
    forceRerenderMatterPresentation() {
      this.componentKey += 1;
      this.$bvModal.hide(`modal-edit-matter-${this.matter.id}`);
      if (this.matter) this.fetchInitLti(this.matter.matter);
    },
    updateDescription(old_matter, text) {
      let payload = {
        matter_id: old_matter.id,
        item: {
          description: text,
        },
      };
      old_matter.description = text;
      this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
        toast(
          "Descripción de " +
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) +
            " actualizada."
        );
      });
    },
    createProfileMatterDescriptors(title) {
      if (isNaN(title.id)) {
        this.$store
          .dispatch(names.POST_EGRESS_PROFILE_MATTER_DESCRIPTORS, title)
          .then((response) => {
            if (this.matter && !this.matter.use_matter_values) {
              this.createEpmMiddleDescriptors("", response);
            } else {
              this.createMatterMiddleDescriptors("", response);
            }
          });
      }
    },
    deleteTitle(object) {
      this.$store
        .dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, {
          descriptor_id: object.id,
        })
        .then((response1) => {
          this.$store
            .dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS, {
              descriptor_id: object.id,
            })
            .then((response2) => {
              const response = response1.concat(response2);
              this.$swal({
                title:
                  "¿Está seguro de que desea eliminar el título descriptivo?",
                text: `Esta acción no se podrá revertir y se eliminarán los objetos referenciados en la parte inferior.`,
                type: "warning",
                footer: `<ul class="w-100" style="max-height: 300px; overflow-y: auto;">
            <li>Título: ${object.title}</li>
            ${response
              .map(
                (item) =>
                  `<li>contenido: ${this.$htmlToPlainText(
                    item.description
                  )}</li>`
              )
              .join("")}
              </ul>`,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.value) {
                  this.$store.dispatch(
                    names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS,
                    object.id
                  );
                }
              });
            });
        });
    },
    deleteProfileMatterDescriptors(EpmMiddleDescriptor) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar el contenido del título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_EPM_MIDDLE_DESCRIPTORS,
            EpmMiddleDescriptor.id
          );
        }
      });
    },
    deleteMatterMiddleDescriptors(matterMiddleDescriptor) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar el contenido del título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_M_MIDDLE_DESCRIPTORS,
            matterMiddleDescriptor.id
          );
        }
      });
    },
    updateProfileMatterDescriptors(title) {
      if (!isNaN(title.id)) {
        let payload = {
          id: title.id,
          item: {
            title: title.title,
            is_default: title.is_default,
          },
        };
        this.$store.dispatch(
          names.PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS,
          payload
        );
      }
    },
    createEpmMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.egress_profile_matter_id) {
        let item = {
          egress_profile_matter: this.egress_profile_matter_id,
          descriptor: object.id,
          description: text,
          order: 1 + this.epmMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_EPM_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateEpmMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateVisibleEpmMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateOrderEpmMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.epm_middle_descriptors.findIndex((x) => x.id == element.id) !=
              -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateOrderMatterMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.m_middle_descriptors.findIndex((x) => x.id == element.id) != -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateVisibleMatterMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    createMatterMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.matter) {
        let item = {
          matter: this.matter.matter,
          descriptor: object.id,
          description: text,
          order: 1 + this.matterMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_M_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateMatterMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    cycleOrder(cycle_id) {
      if (!cycle_id && !this.egressProfile) return "-";
      let cycleOrder = this.profile_cycles.find(
        (x) => x.cycle == cycle_id && x.egress_profile == this.egressProfile.id
      );
      return cycleOrder ? cycleOrder.order : "";
    },
    updateCompetences(competence_ids) {
      let get_matter = this.matter;
      this.$store
        .dispatch(names.UPDATE_EGRESS_PROFILE_MATTER, get_matter)
        .then((response) => {
          if (response) {
            let payload = {
              egress_profile_matter_id: get_matter.id,
              item: {
                competences: competence_ids,
              },
            };
            this.patchCompetences(payload);
          }
        });
    },
    patchCompetences(payload) {
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload)
        .then((egress_profile_matter_id) => {
          let egress_profile_matter = this.matter;
          if (egress_profile_matter) {
            egress_profile_matter = egress_profile_matter_id;
            toast(
              String(
                this.$getVisibleNames("mesh.competence", true, "Competencias") +
                  " actualizadas."
              )
            );
          }
        });
    },
    redirectToMatrix2() {
      this.$router.push({
        path: `/matrix2/form/${this.matter.egress_profile}`,
      });
    },
    patchMatter(item) {
      if (!this.allows_crud) return;
      if (!this.matter) return;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const payload = {
          matter_id: this.matter.matter,
          item: item,
        };
        this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
          toast(
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) + " actualizada."
          );
        });
      }, 1000);
    },
    redirectToLearningSuitcase(egress_profile_matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningSuitcaseView",
        params: { egress_profile_matter_id: egress_profile_matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningSuitcaseStudent(egress_profile_matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningSuitcaseStudentView",
        params: { egress_profile_matter_id: egress_profile_matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    // redirectToBibliophicResource() {
    //   window.open(this.iframeUrl, "_blank");
    // },
    redirectToLearningSuitcaseTeacher(egress_profile_matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningSuitcaseTeacherView",
        params: { egress_profile_matter_id: egress_profile_matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningExperience(egress_profile_matter_id) {
      let route_data = this.$router.resolve({
        name: "LearningExperiencesView",
        params: { egress_profile_matter_id: egress_profile_matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningEvaluation(egress_profile_matter_id) {
      let route_data = this.$router.resolve({
        name: "LearningEvaluationView",
        params: { egress_profile_matter_id: egress_profile_matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningMatterDescription(egress_profile_matter_id) {
      let route_data = this.$router.resolve({
        name: "LearningMatterDescription",
        params: { egress_profile_matter_id: egress_profile_matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    fetchInitLti(matter_id) {
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        window.location.hostname !== "localhost"
      )
        this.$restful
          .Get(`/integrations/lti_launcher/?matter=${matter_id}`)
          .then(() => {
            this.show_bibliographic_resources = true;
            this.bibliographic_resources_not_found = false;
          })
          .catch((error) => {
            if (error.response) {
              const status = error.response.status;
              if (status === 406 || status === 404) {
                this.show_bibliographic_resources = true;
                this.bibliographic_resources_not_found = true;
              }
            }
          });
    },
    openModalCompetenceUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.matter) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.matter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            intelligence_option: 4,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [4],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
            disabled_selected_competence_unit: true,
          });
        });
      }
      this.$bvModal.show(`add-unit-competence-modal`);
    },

    hideModalCompetenceUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.matter) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.matter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            competence_unit_id: null,
            intelligence_option: 2,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [2, 3, 4, 5, 6],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
            disabled_selected_study_unit: null,
            disabled_selected_competence_unit: null,
          });
        });
      }
    },
  },
  watch: {},
  mounted() {
    if (this.user)
      this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
    this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
  },
  created() {
    this.$store.dispatch(names.FETCH_SUPPORT_RESOURCE_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION);
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS, {
      egress_profile_matter_id: this.egress_profile_matter_id,
    });
    this.$store.dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS, {
      egress_profile_matter_id: this.egress_profile_matter_id,
    });
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((response) => {
        this.fetchInitLti(response.matter);
        this.$store.dispatch(
          names.FETCH_EVALUATION_CRITERIAS_MACROS,
          response.matter
        );
        // this.fetchSupportResource(response.matter);
        this.$store.dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, {
          matter_id: response.matter,
        });
        this.$store.dispatch(names.FETCH_RA_MACROS, {
          matter_id: response.matter,
        });
        if (
          this.institution &&
          (this.institution.internal_use_id == "ciisa_uss" ||
            this.institution.show_competence_unit)
        )
          this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
            matter__id: response.matter,
          });
        this.$store
          .dispatch(names.FETCH_EGRESS_PROFILE, response.egress_profile)
          .then((egress_profile) => {
            this.$store
              .dispatch(names.FETCH_MATTER, response.matter)
              .then((matter) => {
                this.$nextTick(() => {
                  if (
                    !egress_profile.is_closed &&
                    !matter.is_closed &&
                    !response.is_closed
                  )
                    this.$store.commit(
                      names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR,
                      true
                    );
                  this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
                    visible_intelligence_options: [2, 3, 4, 5, 6, 7],
                    disabled_selected_egress_profile: true,
                    disabled_selected_egress_profile_matter: true,
                  });
                  this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
                    egress_profile_id: response.egress_profile,
                    egress_profile_matter_id: this.egress_profile_matter_id,
                    competence_unit_id: null,
                    intelligence_option: 2,
                  });
                });
                this.$store.commit(
                  names.MUTATE_TITLE_OF_THE_PRINT,
                  `${this.$getVisibleNames(
                    "manual.matter_program",
                    false,
                    "Programa de la Asignatura"
                  )} - ${matter.code} ${matter.name}`
                );
              });
            this.$store
              .dispatch(names.FETCH_CAREER, egress_profile.career)
              .then((career) => {
                this.$store.dispatch(names.FETCH_CAPACITIES, {
                  faculty_id: career.faculty,
                });
              });
          });
        this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
          egress_profile_id: response.egress_profile,
        });
        this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
          egress_profile_id: response.egress_profile,
        });
        this.$store.dispatch(names.FETCH_COMPETENCES, response.egress_profile);
        this.$store.dispatch(names.FETCH_STUDY_UNITS, response.matter);
      });
    this.$store.dispatch(names.FETCH_TAXONOMIES);
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.container-header-matter-program {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-close-open-matter {
  margin-top: 12px;
  padding: 0px;
}
.btn-close-open-matter:hover {
  color: rgba(0, 0, 0, 0.5);
}
.title-competences {
  color: black !important;
}
.icon-download {
  padding-bottom: 2px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.learning-suitcase-btn {
  margin-left: auto;
  margin-right: 0 !important;
}
table {
  width: 100%;
  table-layout: auto;
}
.criteria {
  width: 80%;
  text-align: left;
  padding-left: 0.4em;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
table td input[type="text"] {
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  min-height: 100%;
}
.table-ecmacro th,
.table-ecmacro td {
  border-left: 1px !important;
  border-bottom: 1px !important;
}
.table-ecmacro th {
  border-top: 1px !important;
}
.table-ecmacro th:last-of-type,
.table-ecmacro td:last-of-type {
  border-right: none;
}
p {
  /* text-align: justify; */
  padding-left: 3px;
}
.wrapper {
  /* margin: 15px;*/
  padding: 10px;
}
li {
  text-align: justify;
}
.tr-competences {
  overflow-x: auto;
}
.competences-header {
  color: black !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}
.competences-header span {
  display: inline-flex;
  margin-left: 6em;
}
.btn-edit-competences {
  margin-left: auto;
  margin-right: 0;
}
.learning-suitcase-buttons {
  margin-left: 0;
  margin-right: auto;
  display: -webkit-box;
  margin-bottom: 0.5em;
  transform: translateY(-10%);
}
</style>